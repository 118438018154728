import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/css/main.css";
import "bootstrap/dist/css/bootstrap.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Footer from "modules/home/components/footer";
import Header from "modules/home/components/header";
import people1 from "../../../assets/people1.jpg";
import people2 from "../../../assets/people2.jpg";
import people3 from "../../../assets/people3.jpg";
import people4 from "../../../assets/people4.jpg";
import people5 from "../../../assets/people5.jpg";

const Team = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const responsive2 = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 3 },
  };

  const items2 = [
    { name: "KEN POWELL", img: people1 },
    { name: "KEN POWELL", img: people1 },
    { name: "KEN POWELL", img: people1 },
    { name: "LISA GONZALES", img: people2 },
    { name: "KEVIN WASHINGTON", img: people3 },
    { name: "BARBARA POWELL", img: people4 },
    { name: "BARAYN POWELL", img: people5 },
    { name: "BARBARA POWELL", img: people4 },
  ];

  const handleDragStart = (e) => {
    setActiveIndex(parseInt(e.target.getAttribute("data-value")));
  };

  const handleDragEnd = () => {
    setActiveIndex(0);
  };

  const renderItems = () => {
    return items2.map((item, index) => (
      <div
        key={index}
        className={`item ${activeIndex === index + 1 ? "center" : ""}`}
        data-value={index + 1}
        onMouseDown={handleDragStart}
        onTouchStart={handleDragStart}
        onTouchEnd={handleDragEnd}
      >
        <div className="testmonial-wrps">
          <div className="testm-cards">
            <img
              className="test-user-imgs"
              src={item.img}
              alt={`User ${index}`}
            />
            <div>
              <h3 className="test-user-titles">{item.name}</h3>
              <span className="autherNames">@sweetgirl</span>
            </div>
          </div>
          <p>
            All services at the highest level, you now my favorite place to
            play billiard. I advise everyone and professionals and amateurs.
          </p>
        </div>
      </div>
    ));
  };

  return (
    <>
      <section>
        <div className="headerteam-bg">
          <Header />
          <div className="banner-caption">
            <div></div>
            <div style={{ width: "70%", marginTop: "12%" }}>
              <span style={{ marginLeft: "23%" }}>SPORT CLUB</span>
              <h2 style={{ marginLeft: "18%" }}>TEAM</h2>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />

        <section className="pt-4 pb-0" style={{ marginBottom: "-20px" }}>
          <div className="text-center">
            <div className="short-title">TEAMS</div>
            <h2 className="section-title text-center mb-0">
              OUR CLUB COACHES
            </h2>
          </div>
        </section>
        <section className="pt-0 pb-0 mb-0 bg-coach"></section>
        <section className="testimoials-pnl">
          <div className="text-center">
            <div className="short-title">TESTIMONIALS</div>
            <h2 className="section-title text-center mb-4">
              WHAT DO PEOPLE SAY
            </h2>
          </div>
          <AliceCarousel
            mouseTracking
            items={renderItems()}
            responsive={responsive2}
            paddingLeft={0}
            paddingRight={100}
            controlsStrategy="alternate"
          />
        </section>

        <Footer />
      </section>
    </>
  );
};

export default Team;
