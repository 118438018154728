import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/css/main.css";
import "bootstrap/dist/css/bootstrap.css";
import welcomeBanner from "../../../assets/welcome-banner.jpg";
import event1 from "../../../assets/event1.jpg";
import Gpin from "../../../assets/Gpin.png";
import ICNShare from "../../../assets/share-icon.png";
import latestPhoto from "../../../assets/latest-photo.jpg";
import fImg1 from "../../../assets/featured1.jpg";
import fImg2 from "../../../assets/featured2.jpg";
import fImg3 from "../../../assets/featured3.jpg";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Footer from "modules/home/components/footer";
import Header from "modules/home/components/header";
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import aboutustip1 from "../../../assets/aboutsustip1.jpg"
import aboutustip2 from "../../../assets/aboutustip2.jpg"
import aboutustip3 from "../../../assets/aboutustip3.jpg"
import aboutustip4 from "../../../assets/aboutustip4.jpg"
import aboutustip5 from "../../../assets/aboutustip5.jpg"
import aboutustip6 from "../../../assets/aboutustip6.jpg"
import aboutustip7 from "../../../assets/tip7.jpg"
import aboutustip8 from "../../../assets/tip8.jpg"
import aboutustip9 from "../../../assets/tip9.jpg"
import { useNavigate } from "react-router-dom";
const ContactPage = () => {
           
  return (
    <>
      <section>
      
        <div className="headercontact-bg">
        <Header />
        <div className="banner-caption">
            <div></div>
            <div style={{ width: "70%", marginTop: "9%" }}>
              <span style={{ marginLeft: "23%" }}>MORE INFO</span>
              <h2 >WE'RE HERE FOR YOU</h2>
            </div>
          </div>
          
        </div>

        <Footer />
      </section>
    </>
  );
};

export default ContactPage;
