import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Header from "modules/home/components/header";
import Footer from "modules/home/components/footer";
import { Grid, TextField, Button } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
// Import the ImageGallery component
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import aboutusTIP2 from "../../../assets/aboutsustip1.jpg"
import aboutustip2 from "../../../assets/aboutustip2.jpg"
import aboutustip3 from "../../../assets/aboutustip3.jpg"

let theme = createTheme();

const styles = {
  container: {
    marginTop: '40px',
  },
  categories: {
    marginLeft:'7%',
    marginBottom: '20px',

  },
  recentPost: {
    marginTop: '30%',
    marginLeft:'7%'
  },
  popularTags: {
    marginTop: '80px',
  },
  weather: {
    display: 'flex',
    alignItems: 'center',
  },
  postContent: {
    backgroundColor: 'orange',
    borderRadius: '5px',
    padding: '5%',
    fontWeight: 'bold',
  },
  button: {
    marginLeft: '40px',
    marginBottom: '10%',
  },
};
theme = responsiveFontSizes(theme);
const TIP4 = () => {
  
  
  return (
    <>
      
        <div className="TIP4gallery">
          <Header />
          <div className="banner-caption">
            <div></div>
            
          </div>
        </div>
        <Grid container spacing={2} sx={styles.container}>
      <Grid item xs={12} sm={4}>
        <div sx={styles.categories}>
          <h4 style={{ fontWeight: 'bold',marginLeft:'7%' }}>CATEGORIES</h4>
          <div style={{ marginTop: '60px' }}>
            <span style={{marginLeft:'7%'}}>Entertainment <span style={{ marginLeft: '20px', marginBottom: '20px' }}>(3)</span></span><br />
            <span style={{marginLeft:'7%'}}>Life Style<span style={{ marginLeft: '72px' }}>(4)</span></span><br />
            <span style={{marginLeft:'7%'}}>Photography<span style={{ marginLeft: '40px' }}>(2)</span></span><br />
            <span style={{marginLeft:'7%'}}>Technology<span style={{ marginLeft: '52px' }}>(2)</span></span><br />
            <span style={{marginLeft:'7%'}}>Tips<span style={{ marginLeft: '112px' }}>(3)</span></span>
          </div>
          <h4 style={styles.recentPost}>RECENT POST</h4>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10%',marginLeft:'7%' }}>
            <img src={aboutusTIP2} style={{ width: '90px', height: '80px', marginRight: '10px' }} />
            <span className='abouttip' style={{ fontSize: '14px' }}>EPIC RIDE: FAMILY BACKPACKING IN COSTA RICA</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10%',marginLeft:'7%' }}>
            <img src={aboutustip2} style={{ width: '90px', height: '80px', marginRight: '10px' }} />
            <span className='abouttip' style={{ fontSize: '14px' }}>SMALL BUSINESS OWNERS ARE FULL OF PASSION AND SMILES</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10%',marginLeft:'7%' }}>
            <img src={aboutustip3} style={{ width: '90px', height: '80px', marginRight: '10px' }} />
            <span className='abouttip' style={{ fontSize: '14px' }}>EVERYTHING YOU NEED TO KNOW ABOUT NEW ROOM RITUALS</span>
          </div>
          <div style={{ marginTop: '80px' }}>
            <h4 style={{ fontWeight: 'bold',marginLeft:'7%' }}>POPULAR TAGS</h4>
            <div style={{ display: 'flex', flexWrap: 'wrap' ,marginLeft:'7%'}}>
              <div style={{ padding: '5px 10px', margin: '5px', borderRadius: '5px', border: '1px solid #e0e0e0' }}>Club</div>
              <div style={{ padding: '5px 10px', margin: '5px', borderRadius: '5px', border: '1px solid #e0e0e0' }}>Game</div>
              <div style={{ padding: '5px 10px', margin: '5px', borderRadius: '5px', border: '1px solid #e0e0e0' }}>Office</div>
              <div style={{ padding: '5px 10px', margin: '5px', borderRadius: '5px', border: '1px solid #e0e0e0' }}>Relax</div>
              <div style={{ padding: '5px 10px', margin: '5px', borderRadius: '5px', border: '1px solid #e0e0e0' }}>Technology</div>
              <div style={{ padding: '5px 10px', margin: '5px', borderRadius: '5px', border: '1px solid #e0e0e0' }}>Travel</div>
              <div style={{ padding: '5px 10px', margin: '5px', borderRadius: '5px', border: '1px solid #e0e0e0' }}>Weather</div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={8}>
        <div style={styles.weather}>
          <h4 style={{ color: 'orange' }}>OFFICE</h4>
          <p style={{ marginLeft: '60%' }}>BY <span className="golden">ADMIN</span> · MARCH 14, 2017</p>
        </div>
        <span className="hedvig-letters-sans">US PRESIDENT ADDRESS CALMS THE NEW EMERGING MARKETS</span>
        <p>Technology is the present world. It affects people’s daily lives. Whether it inspires somebody to 
          be the master at videogames or makes somebody a computer hacker. It has changed the generation of
          teenagers/young adults rapidly. It has made the generation more greedy, ignorant, and lazy</p><br />
        <p>For instance, my parents did not have fancy cell phones or high-tech computers back then because
          it was never created. They only had a bike and ball until it was worn out. They never threw a fit 
          for not getting what they wanted or sat at home, playing videogames for numerous hours.</p>
        <br />
        <div style={{ backgroundColor: 'orange', borderRadius: '5px' }}>
          <p style={{ padding: '5%', fontWeight: 'bold' }}>Technology is just a tool. In terms of getting the kids working together and motivating them, the 
            teacher is the most important.
            – Bill Gates</p>
        </div>
        <p>They would ALWAYS be outside, whether the weather was hot or cold, playing soccer or 
          having a snowball fight with their friends. And when they didn’t want to go outside,
          they sat down, read a book, played cards, or even drank hot cocoa. Nowadays, it’s
          all about getting an iPod touch or a phone with a touch screen. Soon, people will
          have chairs that will fly around to get a soda from the fridge so they don’t have to 
          get their butt up and walk. Though there seems to 
          be more negative influence of technology, there may still be some silver lining.</p><br />
        <p>As discussed, there are many cons to technology, but there are some pros too. 
          It could be useful for people’s lives and it could be used to hurt people’s lives.
          The way technology should be used is as a tool and for helpful reasons, and should not be used for hours 
          of laziness. Overall, technology influences every aspect of people’s lives.</p>
        <br />
        <h2 style={{ fontWeight: 'bold' }}>Leave your comments</h2>
        <div >
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Your Name" id="fullWidth" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Your Email" id="fullWidth" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Comments"
                id="fullWidth"
                multiline // Enables multiline input
                rows={4} // Sets the number of rows
                sx={{ height: '150px' }} // Custom height
              />
            </Grid>
          </Grid>
          <p style={{ fontWeight: 'bolder', color: '#646464' }}>
            Save my name, email, and website in this browser for the next time I comment.
          </p>
        </div>
        <button className="btn-view-more" style={styles.button}>SEND</button>
      </Grid>
    </Grid>






        <div className="container">
        
       
 
  <br/>

        </div>

        <Footer />
      
    </>
  );
};

export default TIP4;
