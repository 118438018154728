import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../../styles/css/main.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-alice-carousel/lib/alice-carousel.css";
import Footer from "modules/home/components/footer";
import Header from "modules/home/components/header";
import logo from "../../../assets/logo3.png"
import logo1 from "../../../assets/logo4.png"
import logo2 from "../../../assets/logo5.png"
import logo3 from "../../../assets/logo6.png"
import logo4 from "../../../assets/logo7.png"
import logo5 from "../../../assets/logo8.png"

const MemberShip = () => {




  return (
    <>
      <section>
        
        <div className="headermembership-bg">
        <Header />
        <div className="banner-caption">
            <div></div>
            <div style={{ width: "70%", marginTop: "12%" }}>
              <span style={{ marginLeft: "20%" }}>SPORT CLUB</span>
              <h2>MEMBERSHIP</h2>
            </div>
          </div>
        </div>



        <section
          className="pt-5 pb-5 mb-4"
          style={{ backgroundColor: "#f7f4f1" }}
        >
          <div className="text-center">
            <div className="short-title">MORE CONVENIENCE</div>
            <h2 className="section-title text-center mb-4">
            YOU WILL LIKE IT
            </h2>
            <div class="container">
  <div class="row">
    <div class="col-sm">
    <h3 style={{fontWeight:'bold'}}><img src={logo} style={{marginRight:'20px'}} />First-class service</h3>
    <p>Let your Members book the resources <br/>such as meeting room and projector.</p>
    </div>
    <div class="col-sm">
    <h3 style={{fontWeight:'bold',marginRight:'70px'}}><img src={logo1} style={{marginRight:'20px'}} />Club card</h3>
    <p>Let your Members book the resources <br/>such as meeting room and projector.</p>
    </div>
    <div class="col-sm">
    <h3 style={{fontWeight:'bold'}}><img src={logo2} style={{marginRight:'20px'}} />Personal trainer</h3>
    <p>Let your Members book the resources <br/>such as meeting room and projector.</p>
  </div>
  </div>
</div>
      <br/>
      <br/>
      <div class="container">
  <div class="row">
    <div class="col-sm">
    <h3 style={{fontWeight:'bold',marginRight:'60px'}}><img src={logo3} style={{marginRight:'20px'}}/>Tournaments</h3>
    <p>Let your Members book the resources <br/>such as meeting room and projector.</p>
    </div>
    <div class="col-sm">
    <h3 style={{fontWeight:'bold',marginRight:'80px'}}><img src={logo4} style={{marginRight:'20px'}}/>Flexitime</h3>
    <p>Let your Members book the resources <br/>such as meeting room and projector.</p>
    </div>
    <div class="col-sm">
  <div class="row">
    
    <div class="col">
      <h3 style={{fontWeight: 'bold'}}><img src={logo5} style={{marginRight:'20px',marginLeft:'40px'}}/>Work on weekends</h3>
    </div>
  </div>
  <p>Let your Members book the resources <br/>such as meeting room and projector.</p>
</div>

  </div>
</div>
 
          </div>
          
        </section>



        <section className="mb-3 pb-4 mt-5">
          <div className="text-center">
            <div className="short-title">PRICING</div>
            <h2 className="section-title text-center mb-5">CLUB MEMBERSHIP</h2>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-4 pb-5">
                <div className="price-col">
                  <h4>Youth Member</h4>
                  <div className="price-title">
                    <sup>$</sup>
                    <span>27</span>
                    <sub>/month</sub>
                  </div>
                  <ul>
                    <li>
                      A person who is at least 17 (unless participating in a
                      club
                    </li>
                    <li>sanctioned with youth program).</li>
                  </ul>
                  <div>
                    <a href="#" className="btn-blank">
                      ADD TO CARD
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="price-col price-col-act">
                  <h4>STANDARD</h4>
                  <div className="price-title">
                    <sup>$</sup>
                    <span>69</span>
                    <sub>/month</sub>
                  </div>
                  <ul>
                    <li>
                      Enjoy the full rights and privileges of the club. All
                      services at the
                    </li>
                    <li>highest level</li>
                  </ul>
                  <div>
                    <a href="#" className="btn-blank">
                      ADD TO CARD
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="price-col">
                  <h4>PREMIUM</h4>
                  <div className="price-title">
                    <sup>$</sup>
                    <span>99</span>
                    <sub>/month</sub>
                  </div>
                  <ul>
                    <li>
                      Enjoy the full rights and privileges of the club. All
                      services at the
                    </li>
                    <li>highest level</li>
                  </ul>
                  <div>
                    <a href="#" className="btn-blank">
                      ADD TO CARD
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </section>
    </>
  );
};

export default MemberShip;
