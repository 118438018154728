import React, { useRef } from "react";
import Logo from "../../../../../src/assets/logo.png";
import { NavLink } from "react-router-dom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
const subMenuStyles = {
  position: 'absolute',
  background: 'black',
  color: 'white',
  padding: '10px',

  
  zIndex: '200', 
  display: 'none' 
};

const subMenuItemStyles = {
  listStyleType: 'none',
  margin: '0',
  padding: '0',
  color:' #fff'
};

const specialsHoverStyles = {
  position: 'relative', 
};

const Header = ({ title }) => {
 
  const handleSpecialsHover = () => {
    document.getElementById('subMenu').style.display = 'block'; 
  };

  const handleSpecialsLeave = () => {
    document.getElementById('subMenu').style.display = 'none'; 
  };
  return (
    <>
      
        <div className="header-bg-strip"></div>
        <div className="header-wrp">
          <div style={{ zIndex: "100" }}>
            <NavLink exact activeClassName="active" to={"/"}>
              <img src={Logo} alt="" className="logo-wdth" />
            </NavLink>
          </div>
          
          <div className="head-nav" style={{ zIndex: "100" }}>
            <ul>
              <li className="">
                <NavLink exact activeClassName="active" to={"/"}>
                  HOME
                </NavLink>
              </li>
              <li className="">
                <NavLink exact activeClassName="active" to={"/gallery"}>
                  GALLERY
                </NavLink>
              </li>
              <li className="" style={specialsHoverStyles} onMouseEnter={handleSpecialsHover} onMouseLeave={handleSpecialsLeave}>
          <NavLink activeClassName="active" to={"/special"}>
                  SPECIALS
                </NavLink>
                <div id="subMenu" style={subMenuStyles}>
            <ul style={subMenuItemStyles}>
              <li><NavLink to="/membership">MEMBERSHIP</NavLink></li>
              <li><NavLink to="/team">TEAM</NavLink></li>
            </ul>
          </div>
        </li>
              
              
              <li className="">
                <NavLink exact activeClassName="active" to={"/contacts"}>
                  ABOUT US
                </NavLink>
              </li>
              <li className="">
                <NavLink exact activeClassName="active" to={"/contacts"}>
                  BOOK
                </NavLink>
              </li>
              <li className="">
                <NavLink exact activeClassName="active" to={"/contacts"}>
                  EVENTS
                </NavLink>
              </li>
              <li className="">
                <NavLink exact activeClassName="active" to={"/contacts"}>
                  SHOP
                </NavLink>
              </li>
              <li className="">
                <NavLink exact activeClassName="active" to={"/contacts"}>
                  BLOG
                </NavLink>
              </li>
              <li className="">
                
                <NavLink activeClassName="active" to={"/contacts"}>
                  Contact
                </NavLink>
              </li>
              <li className="">
                <ShoppingCartIcon sx={{color: '#fda329'}}/>
              </li>
              {/* <li className="aboutBlock">
                <NavLink activeClassName="active" to={"/pricing"}>
                  Pricing
                </NavLink>
              </li>

              <li className="aboutBlock">
                <NavLink activeClassName="active" to={"/faq"}>
                  FAQ
                </NavLink>
              </li> */}
              
            </ul>
          </div>
        </div>
        
      
    </>
  );
};

export default Header;
