import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Header from "modules/home/components/header";
import Footer from "modules/home/components/footer";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
// Import the ImageBookPage component

const BookPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");

  const handleCategoryChange = (category) => {
    
    setSelectedCategory(category);
  };
  
  
  return (
    <>
      <section>
        <div className="headerBookPage-bg">
          <Header />
          <div className="banner-caption">
            <div></div>
            <div style={{ width: "70%", marginTop: "12%" }}>
              <h2 style={{marginLeft:'10%'}}>BOOK THE <h2 style={{marginLeft:'10%'}}>GAME</h2></h2>
              <span style={{color:'white'}}>You have a great chance to book today a great game</span>
              
            </div>

          </div>
          <div style={{marginTop:'40px'}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar sx={{background:'white',borderRadius:'5px'}}/>
    </LocalizationProvider>

            </div>
        </div>


       

        <Footer />
      </section>
    </>
  );
};

export default BookPage;
