import Footer from "modules/home/components/footer";
import Header from "modules/home/components/header";
import React from "react";

const Contact = () => {
  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="page-title">Contact</h1>
            <p style={{ height: "200px", padding: "20px" }}>Coming Soon...</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
