import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Header from "modules/home/components/header";
import Footer from "modules/home/components/footer";
import ImageGallery from "./ImagesGallery";
// Import the ImageGallery component

const Gallery = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");

  const handleCategoryChange = (category) => {
    
    setSelectedCategory(category);
  };
  
  
  return (
    <>
      <section>
        <div className="headergallery-bg">
          <Header />
          <div className="banner-caption">
            <div></div>
            <div style={{ width: "70%", marginTop: "12%" }}>
              <span style={{ marginLeft: "25%" }}>GALLERY</span>
              <h2>THE LATEST PHOTO</h2>
            </div>
          </div>
        </div>

        <div className="head-nav-gallery" style={{ zIndex: "100" }}>
          <ul>
            <li className="">
              <NavLink exact activeClassName="active" onClick={() => handleCategoryChange("all")} style={{color: selectedCategory === "all" ? "#fda329" : "inherit"}}>
                ALL
              </NavLink>
            </li>
            <li className="">
              <NavLink exact activeClassName="active" onClick={() => handleCategoryChange("bar")} style={{color: selectedCategory === "bar" ? "#fda329" : "inherit"}}>
                BAR
              </NavLink>
            </li>
            <li className="">
              <NavLink exact activeClassName="active" onClick={() => handleCategoryChange("game-room")} style={{color: selectedCategory === "game-room" ? "#fda329" : "inherit"}}>
                GAME ROOM
              </NavLink>
            </li>
            <li className="">
              <NavLink onClick={() => handleCategoryChange("tournaments")} style={{color: selectedCategory === "tournaments" ? "#fda329" : "inherit"}}>
                TOURNAMENTS
              </NavLink>
            </li>
          </ul>
        </div>


        <div className="container">
          
          <ImageGallery selectedCategory={selectedCategory} /> {/* Render the ImageGallery component */}
        </div>

        <Footer />
      </section>
    </>
  );
};

export default Gallery;
