import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchHomeData } from './homeActions';

const initialState = {
  status: 'idle',
};
export const getHomeData = createAsyncThunk(
  'GET_HOME_DATA',
  async (requestObject) => {
    const { data, status } = await fetchHomeData(requestObject);
    return { data, status };
  }
);
export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHomeData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getHomeData.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(getHomeData.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectIsLoading = (state) => state.home.status === 'loading';
export default homeSlice.reducer;
