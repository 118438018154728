import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/css/main.css";
import "bootstrap/dist/css/bootstrap.css";
import welcomeBanner from "../../../assets/welcome-banner.jpg";
import event1 from "../../../assets/event1.jpg";
import Gpin from "../../../assets/Gpin.png";
import ICNShare from "../../../assets/share-icon.png";
import latestPhoto from "../../../assets/latest-photo.jpg";
import fImg1 from "../../../assets/featured1.jpg";
import fImg2 from "../../../assets/featured2.jpg";
import fImg3 from "../../../assets/featured3.jpg";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Footer from "modules/home/components/footer";
import Header from "modules/home/components/header";
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import aboutustip1 from "../../../assets/aboutsustip1.jpg"
import aboutustip2 from "../../../assets/aboutustip2.jpg"
import aboutustip3 from "../../../assets/aboutustip3.jpg"
import aboutustip4 from "../../../assets/aboutustip4.jpg"
import aboutustip5 from "../../../assets/aboutustip5.jpg"
import aboutustip6 from "../../../assets/aboutustip6.jpg"
import { useNavigate } from "react-router-dom";
const AboutUs = () => {
    const navigate=useNavigate()
    const handlenavigate=()=>{
          navigate('/tip1')
        }
    const handlenavigate1=()=>{
          navigate('/tip2')
        }
    const handlenavigate2=()=>{
          navigate('/tip3')
        }
    const handlenavigate3=()=>{
          navigate('/tip4')
        }
    const handlenavigate4=()=>{
          navigate('/tip5')
        }
        const handlenavigate5=()=>{
          navigate('/tip6')
        }       
  return (
    <>
      <section>
        
        <div className="headeraboutus-bg">
        <Header />
        <div className="banner-caption">
            <div></div>
            <div style={{ width: "70%", marginTop: "12%" }}>
              <span style={{ marginLeft: "23%" }}>SPORT CLUB</span>
              <h2 style={{ marginLeft: "10%" }}>ABOUT US</h2>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row welcome-section">
            <div className="col-md-5">
              <img src={welcomeBanner} className="img-responsive" />
            </div>
            <div className="col-md-7">
              <span>ABOUT CLUB</span>
              <h1>WELCOME TO OUR CLUB</h1>
              <p
                style={{
                  fontWeight: "600",
                  marginBottom: "31px",
                  marginTop: "20px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit,sed do
                eiusmod tempor incididunt ut labore.
              </p>
              <p>
                Duis aute irure dolor in reprehenderit in voluptate velit
                essecillum dolore eu fugiat nulla pariatur. Excepteur sint
                occaecatcupidatat non proident, sunt in culpa qui officia
                deserunt mollitanim id est laborum. Sed ut perspiciatis unde
                omnis iste natuserror sit voluptatem accusantium doloremque.
              </p>
            </div>
          </div>
        </div>

      



        <section className="pt-4 pb-0" style={{ marginBottom: "-20px" }}>
          <div className="text-center">
            <div className="short-title">TEAMS</div>
            <h2 className="section-title text-center mb-0">OUR CLUB COACHES</h2>
          </div>
        </section>
        <section className="pt-0 pb-0 mb-0 bg-coach"></section>
        <section className="pt-4 pb-0" style={{ marginBottom: "-20px" }}>
          <div className="text-center">
            <div className="short-title">DO NOT MISS</div>
            <h2 className="section-title text-center mb-0">OUR TIPS</h2>
          </div>
        </section>
        <div class="container">
  <div class="row">
    <div class="col-sm">
    <Card sx={{ maxWidth: 445 }}>
  <CardMedia
    sx={{ height: 230 }}
    image={aboutustip1}
    title="green iguana"
  />
  <CardContent>
    <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:'bold'}}>
      EPIC RIDE: FAMILY BACKPACKING IN COSTA RICA
    </Typography>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <p style={{ margin: 0 }}>BY <span style={{ color: '#fda329' }}>ADMIN</span> . JUNE 29, 2017</p>

      <p style={{ margin: 0 }}>NO COMMENTS</p>
    </div>
    <Typography variant="body2" color="text.secondary"sx={{marginTop:'15px'}}>
      Lizards are a widespread group of squamate reptiles, with over 6,000
      species, ranging across all continents except Antarctica
    </Typography>
    <span className="para" style={{ marginTop:'20px' }} onClick={handlenavigate}>READ MORE <ArrowForwardIcon sx={{ fontSize: 'larger' }}/></span>
  </CardContent>
</Card>

    </div>
    <div class="col-sm">
    <Card sx={{ maxWidth: 445 }}>
  <CardMedia
    sx={{ height: 230 }}
    image={aboutustip2}
    title="green iguana"
  />
  <CardContent>
    <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:'bold'}}>
    SMALL BUSINESS OWNERS ARE FULL OF PASSION AND SMILES
    </Typography>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <p style={{ margin: 0 }}>BY <span style={{ color: '#fda329' }}>ADMIN</span> . JUNE 29, 2017</p>

      <p style={{ margin: 0 }}>NO COMMENTS</p>
    </div>
    <Typography variant="body2" color="text.secondary"sx={{marginTop:'15px'}}>
      Lizards are a widespread group of squamate reptiles, with over 6,000
      species, ranging across all continents except Antarctica
    </Typography>
    <span className="para" style={{ marginTop:'20px' }} onClick={handlenavigate1}>READ MORE <ArrowForwardIcon sx={{ fontSize: 'larger' }}/></span>
  </CardContent>
</Card>

    </div>
    <div class="col-sm">
    <Card sx={{ maxWidth: 445 }}>
  <CardMedia
    sx={{ height: 230 }}
    image={aboutustip3}
    title="green iguana"
  />
  <CardContent>
    <Typography gutterBottom variant="h6" component="div" sx={{fontWeight:'bold'}}>
    EVERYTHING YOU NEED TO KNOW ABOUT NEW ROOM RITUALS
    </Typography>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <p style={{ margin: 0 }}>BY <span style={{ color: '#fda329' }}>ADMIN</span> . JUNE 29, 2017</p>

      <p style={{ margin: 0 }}>NO COMMENTS</p>
    </div>
    <Typography variant="body2" color="text.secondary"sx={{marginTop:'15px'}}>
      Lizards are a widespread group of squamate reptiles, with over 6,000
      species, ranging across all continents except Antarctica
    </Typography>
    <span className="para" style={{ marginTop:'20px' }} onClick={handlenavigate2}>READ MORE <ArrowForwardIcon sx={{ fontSize: 'larger' }}/></span>
  </CardContent>
</Card>

    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-sm">
    <Card sx={{ maxWidth: 445 }}>
  <CardMedia
    sx={{ height: 230 }}
    image={aboutustip4}
    title="green iguana"
  />
  <CardContent>
    <Typography gutterBottom variant="h6" component="div" sx={{fontWeight:'bold'}}>
    US PRESIDENT ADDRESS CALMS THE NEW EMERGING MARKETS
    </Typography>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <p style={{ margin: 0 }}>BY <span style={{ color: '#fda329' }}>ADMIN</span> . JUNE 29, 2017</p>

      <p style={{ margin: 0 }}>NO COMMENTS</p>
    </div>
    <Typography variant="body2" color="text.secondary"sx={{marginTop:'15px'}}>
      Lizards are a widespread group of squamate reptiles, with over 6,000
      species, ranging across all continents except Antarctica
    </Typography>
    <span className="para" style={{ marginTop:'20px' }} onClick={handlenavigate3}>READ MORE <ArrowForwardIcon sx={{ fontSize: 'larger' }}/></span>
  </CardContent>
</Card>

    </div>
    <div class="col-sm">
    <Card sx={{ maxWidth: 445 }}>
  <CardMedia
    sx={{ height: 230 }}
    image={aboutustip5}
    title="green iguana"
  />
  <CardContent>
    <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:'bold'}}>
    HOW TO STOP ROBOTS FROM TAKING YOUR JOB
    </Typography>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <p style={{ margin: 0 }}>BY <span style={{ color: '#fda329' }}>ADMIN</span> . JUNE 29, 2017</p>

      <p style={{ margin: 0 }}>NO COMMENTS</p>
    </div>
    <Typography variant="body2" color="text.secondary"sx={{marginTop:'15px'}}>
      Lizards are a widespread group of squamate reptiles, with over 6,000
      species, ranging across all continents except Antarctica
    </Typography>
    <span className="para" style={{ marginTop:'20px' }} onClick={handlenavigate4}>READ MORE <ArrowForwardIcon sx={{ fontSize: 'larger' }}/></span>
  </CardContent>
</Card>

    </div>
    <div class="col-sm">
    <Card sx={{ maxWidth: 445 }}>
  <CardMedia
    sx={{ height: 230 }}
    image={aboutustip6}
    title="green iguana"
  />
  
  <CardContent>
    <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:'bold'}}>
    HOW TECHNOLOGY IS TAKING OVER THE WORLD
    </Typography>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <p style={{ margin: 0 }}>BY <span style={{ color: '#fda329' }}>ADMIN</span> . JUNE 29, 2017</p>

      <p style={{ margin: 0 }}>NO COMMENTS</p>
    </div>
    <Typography variant="body2" color="text.secondary"sx={{marginTop:'15px'}}>
      Lizards are a widespread group of squamate reptiles, with over 6,000
      species, ranging across all continents except Antarctica
    </Typography>
    <span className="para" style={{ marginTop:'35px' }} onClick={handlenavigate5}>READ MORE <ArrowForwardIcon sx={{ fontSize: 'larger' }}/></span>
  </CardContent>
</Card>

    </div>
  </div>
</div>
        <Footer />
      </section>
    </>
  );
};

export default AboutUs;
