import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../../styles/css/main.css";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "modules/home/components/footer";
import Header from "modules/home/components/header";

import welcomeBanner1 from "../../../assets/special1.jpg";
import welcomeBanner2 from "../../../assets/special2.jpg"; // Import additional images
import welcomeBanner3 from "../../../assets/special3.jpg"; // Import additional images

const SpecialsPage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const headerBackgroundImages = [
    welcomeBanner1,
    welcomeBanner2,
    welcomeBanner3,
  ];

  const handleNextImage = () => {
    const nextIndex =
      currentImageIndex === headerBackgroundImages.length - 1
        ? 0
        : currentImageIndex + 1;
    setCurrentImageIndex(nextIndex);
  };

  const handlePreviousImage = () => {
    const previousIndex =
      currentImageIndex === 0
        ? headerBackgroundImages.length - 1
        : currentImageIndex - 1;
    setCurrentImageIndex(previousIndex);
  };

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <>
      <section>
        <div
          className="carousel-container"
          style={{
            backgroundImage: `url(${headerBackgroundImages[currentImageIndex]})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            width: "100%",
            backgroundSize: "cover",
            height: "calc(150vh - 79px)",
            position: "relative",
            transition: "background-image 0.5s ease-in-out", // CSS transition for smooth image change
          }}
        >
          <Header />
          <div className="banner-caption">
            <div></div>
            <div style={{ width: "70%", marginTop: "12%" }}>
              <span style={{ marginLeft: "23%" }}>SPORT CLUB</span>
              <h2>FOOD AND BAR</h2>
            </div>
          </div>
          <p style={{ color: "white", marginLeft: "25%" }}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio id
            aspernatur est aliquid,<br />
            nisi voluptate qui aut, blanditiis eum maiores eos. Commodi nihil
            dignissimos fugit<br />
            blanditiis voluptatem at maiores alias! Lorem ipsum dolor sit amet,
            consectetur adipisicing elit.
          </p>
          <button className="btn-view-more" style={{ marginLeft: "43%" }}>
            BOOK NOW
          </button>
          <ArrowForwardIosIcon
            onClick={handleNextImage}
            style={{
              position: "absolute",
              bottom: "40%",
              right: "5%",
              color: "white",
              fontSize: "5rem",
              cursor: "pointer",
            }}
          />
          <ArrowBackIosIcon
            onClick={handlePreviousImage}
            style={{
              position: "absolute",
              bottom: "40%",
              left: "5%",
              color: "white",
              fontSize: "5rem",
              cursor: "pointer",
            }}
          />
          <div
            className="pagination-points"
            style={{
              position: "absolute",
              bottom: "5%",
            
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              alignItems: "center",
            }}
          >
            {headerBackgroundImages.map((_, index) => (
              <div
                key={index}
                className={`pagination-point ${index === currentImageIndex ? 'active' : ''}`}
                onClick={() => handleDotClick(index)}
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  border: "2px solid #fff", // border for the circular pagination point
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 5px",
                  cursor: "pointer",
                  background: index === currentImageIndex ? "#fca42c" : "transparent", // background color for active pagination point
                }}
              >
                {/* Empty space inside the circle */}
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default SpecialsPage;
