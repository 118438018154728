import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Header from "modules/home/components/header";
import Footer from "modules/home/components/footer";
import event1 from "../../../assets/event1.jpg";
import Gpin from "../../../assets/Gpin.png";
import ICNShare from "../../../assets/share-icon.png";
import latestPhoto from "../../../assets/latest-photo.jpg";
import fImg1 from "../../../assets/featured1.jpg";
import fImg2 from "../../../assets/featured2.jpg";
import fImg3 from "../../../assets/featured3.jpg";
import AliceCarousel from "react-alice-carousel";
const EventsPage = () => {
  
  
  return (
    <>
      <section>
        <div className="headereventsPage-bg">
          <Header />
          <div className="banner-caption">
            <div></div>
            <div style={{ width: "70%", marginTop: "12%" }}>
              <span style={{ marginLeft: "25%" }}>DON'T MISS</span>
              <h2 >UPCOMING EVENTS</h2>
            </div>
          </div>
        </div>
        <section className="bg-gray bg-spc">
          <div className="text-center">
            <div className="short-title">DON'T MISS</div>
            <h2 className="section-title text-center mb-5 sm:mb-1">
              UPCOMING EVENTS
            </h2>
          </div>
          <div className="container bg-white">
            <div className="row upcm-event-section">
              <div className="col-md-3 plr-0">
                <img src={event1} alt="" className="img-wdth" />
              </div>
              <div className="col-md-6 plr-0 border-btm">
                <h3 className="event-sub-title">
                  2016 USBA Nationals Pedro Piedrabuena vs Frank Torres
                </h3>
                <p className="event-text">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do
                </p>
              </div>
              <div className="col-md-3 border-left border-btm">
                <h3 className="event-date">17 JUL - 31 DEC</h3>
                <div className="g-pin-text">
                  <span>
                    <img src={Gpin} />
                  </span>
                  <span>City Hall</span>
                  <span className="blk-spn">Manhattan</span>
                </div>
              </div>
              <div className="col-md-3">
                <img src={ICNShare} alt="" className="icn-share mt-4" />
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-3 text-right">
                <button className="btn-register">Register</button>
              </div>
            </div>
          </div>

          <div className="container bg-white mt-5">
            <div className="row upcm-event-section">
              <div className="col-md-3 plr-0">
                <img src={event1} alt="" className="img-wdth" />
              </div>
              <div className="col-md-6 plr-0 border-btm">
                <h3 className="event-sub-title">
                  2016 USBA Nationals Pedro Piedrabuena vs Frank Torres
                </h3>
                <p className="event-text">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do
                </p>
              </div>
              <div className="col-md-3 border-left border-btm">
                <h3 className="event-date">17 JUL - 31 DEC</h3>
                <div className="g-pin-text">
                  <span>
                    <img src={Gpin} />
                  </span>
                  <span>City Hall</span>
                  <span className="blk-spn">Manhattan</span>
                </div>
              </div>
              <div className="col-md-3">
                <img src={ICNShare} alt="" className="icn-share mt-4" />
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-3 text-right">
                <button className="btn-register">Register</button>
              </div>
            </div>
          </div>

          <div className="container bg-white mt-5 mb-5">
            <div className="row upcm-event-section">
              <div className="col-md-3 plr-0">
                <img src={event1} alt="" className="img-wdth" />
              </div>
              <div className="col-md-6 plr-0 border-btm">
                <h3 className="event-sub-title">
                  2016 USBA Nationals Pedro Piedrabuena vs Frank Torres
                </h3>
                <p className="event-text">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do
                </p>
              </div>
              <div className="col-md-3 border-left border-btm">
                <h3 className="event-date">17 JUL - 31 DEC</h3>
                <div className="g-pin-text">
                  <span>
                    <img src={Gpin} />
                  </span>
                  <span>City Hall</span>
                  <span className="blk-spn">Manhattan</span>
                </div>
              </div>
              <div className="col-md-3">
                <img src={ICNShare} alt="" className="icn-share mt-4" />
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-3 text-right">
                <button className="btn-register">Register</button>
              </div>
            </div>
          </div>
          <div className="text-center mb-5">
            <button className="btn-view-more">View More</button>
          </div>
        </section>

       

        <Footer />
      </section>
    </>
  );
};

export default EventsPage;
