import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Loader } from "components";
import PrivateRoute from "./PrivateRoute";
import Contact from "modules/contact/container";
import Gallery from "components/Common/Gallary/Gallery";
import AllGallery from "components/Common/Gallary/AllGalley";
import GameRoom1 from "components/Common/Gallary/GameRoom1";
import GameRoom2 from "components/Common/Gallary/GmaeRoom2";
import GameRoom3 from "components/Common/Gallary/GameRoom3";
import Bar from "components/Common/Gallary/Bar";
import MemberShip from "components/Common/Specials/MemberShip";
import SpecialsPage from "components/Common/Specials/SpecialsPage";
import Team from "components/Common/Specials/Team";
import AboutUs from "components/Common/Aboutus/AboutUs";
import TIP1 from "components/Common/Aboutus/Tip1";
import TIP2 from "components/Common/Aboutus/Tip2";
import TIP3 from "components/Common/Aboutus/Tip3";
import TIP4 from "components/Common/Aboutus/Tip4";
import TIP5 from "components/Common/Aboutus/Tip5";
import TIP6 from "components/Common/Aboutus/Tip6";
import BookPage from "components/Common/Book/BookPage";
import EventsPage from "components/Common/Events/EventsPages";
import ShopPage from "components/Common/Shop/ShopPage";
import BlogPage from "components/Common/Blog/BlogPage";
import TIP7 from "components/Common/Aboutus/Tip7";
import TIP8 from "components/Common/Aboutus/Tip8";
import TIP9 from "components/Common/Aboutus/Tip9";
import ContactPage from "components/Common/Contact/ContactPage";

const UIControls = lazy(() => import("ui-controls/UIControls"));
const UIComponents = lazy(() => import("components/UIComponents"));
const Dashboard = lazy(() => import("../modules/home/container"));
const Pricing = lazy(() => import("modules/pricing/container"));
const Faq = lazy(() => import("modules/faq/container"));

const Error404 = lazy(() => import("./Error404"));

const Routing = () => (
  <Router>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/pricing"
          element={
            <PrivateRoute>
              <Pricing />
            </PrivateRoute>
          }
        />
        <Route
          path="/faq"
          element={
            <PrivateRoute>
              <Faq />
            </PrivateRoute>
          }
        />
        <Route
          path="/contacts"
          element={
            <PrivateRoute>
              <Contact />
            </PrivateRoute>
          }
        />
        <Route
          path="/contact"
          element={
            <PrivateRoute>
              <ContactPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/gallery"
          element={
            <PrivateRoute>
              <Gallery/>
            </PrivateRoute>
          }
        />
        <Route
          path="/all-gallery"
          element={
            <PrivateRoute>
              <AllGallery/>
            </PrivateRoute>
          }
        />
         <Route
          path="/gameroom-gallery"
          element={
            <PrivateRoute>
              <GameRoom1/>
            </PrivateRoute>
          }
        />
        <Route
          path="/gameroom-gallery2"
          element={
            <PrivateRoute>
              <GameRoom2/>
            </PrivateRoute>
          }
        />
        <Route
          path="/gameroom-gallery3"
          element={
            <PrivateRoute>
              <GameRoom3/>
            </PrivateRoute>
          }
        />
        <Route
          path="/bar"
          element={
            <PrivateRoute>
              <Bar/>
            </PrivateRoute>
          }
        />
        <Route
          path="/membership"
          element={
            <PrivateRoute>
              <MemberShip/>
            </PrivateRoute>
          }
        />
        <Route
          path="/special"
          element={
            <PrivateRoute>
              <SpecialsPage/>
            </PrivateRoute>
          }
        />
        <Route
          path="/team"
          element={
            <PrivateRoute>
              <Team/>
            </PrivateRoute>
          }
        />
        <Route
          path="/aboutus"
          element={
            <PrivateRoute>
              <AboutUs/>
            </PrivateRoute>
          }
        />
        <Route
          path="/tip1"
          element={
            <PrivateRoute>
              <TIP1/>
            </PrivateRoute>
          }
        />
        <Route
          path="/tip2"
          element={
            <PrivateRoute>
              <TIP2/>
            </PrivateRoute>
          }
        />
        <Route
          path="/tip3"
          element={
            <PrivateRoute>
              <TIP3/>
            </PrivateRoute>
          }
        />
        <Route
        path="/tip4"
        element={
          <PrivateRoute>
            <TIP4/>
          </PrivateRoute>
        }
      />
      <Route
        path="/tip5"
        element={
          <PrivateRoute>
            <TIP5/>
          </PrivateRoute>
        }
      />
      <Route
        path="/tip6"
        element={
          <PrivateRoute>
            <TIP6/>
          </PrivateRoute>
        }
      />
      <Route
        path="/book"
        element={
          <PrivateRoute>
            <BookPage/>
          </PrivateRoute>
        }
      />
      <Route
        path="/events"
        element={
          <PrivateRoute>
            <EventsPage/>
          </PrivateRoute>
        }
      />
      <Route
        path="/shop"
        element={
          <PrivateRoute>
            <ShopPage/>
          </PrivateRoute>
        }
      />
      <Route
        path="/blog"
        element={
          <PrivateRoute>
            <BlogPage/>
          </PrivateRoute>
        }
      />
      <Route
        path="/tip7"
        element={
          <PrivateRoute>
            <TIP7/>
          </PrivateRoute>
        }
      />
      <Route
        path="/tip8"
        element={
          <PrivateRoute>
            <TIP8/>
          </PrivateRoute>
        }
      />
      <Route
        path="/tip9"
        element={
          <PrivateRoute>
            <TIP9/>
          </PrivateRoute>
        }
      />
        {process.env.REACT_APP_ENABLE_DEV_PAGES === "true" ? (
          <Route path="/ui-controls" element={<UIControls />} />
        ) : null}
        {process.env.REACT_APP_ENABLE_DEV_PAGES === "true" ? (
          <Route path="/ui-components" element={<UIComponents />} />
        ) : null}
        <Route element={<Error404 />} />
      </Routes>
    </Suspense>
  </Router>
);

export default Routing;
