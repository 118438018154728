import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Header from "modules/home/components/header";
import Footer from "modules/home/components/footer";
import ImageGallery from "./ImagesGallery";
import { Grid, TextField, Button } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
// Import the ImageGallery component
import { createTheme, responsiveFontSizes } from '@mui/material/styles';


let theme = createTheme();
theme = responsiveFontSizes(theme);
const GameRoom1 = () => {
  
  
  return (
    <>
      
        <div className="gameroom1gallery">
          <Header />
          <div className="banner-caption">
            <div></div>
            
          </div>
        </div>
        <div className="container">
        <p>BY <span class="golden">ADMIN</span> · MARCH 14, 2017</p>
       
  <h1 className="hedvig-letters-sans-regular">TITLE 2</h1>
  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio id aspernatur est aliquid, nisi voluptate qui aut, blanditiis eum maiores eos. Commodi nihil dignissimos fugit blanditiis voluptatem at maiores alias! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio id aspernatur est aliquid, nisi voluptate qui aut, blanditiis eum maiores eos. Commodi nihil dignissimos fugit blanditiis voluptatem at maiores alias! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio id aspernatur est aliquid, nisi voluptate qui aut, blanditiis eum maiores eos. Commodi nihil dignissimos fugit blanditiis voluptatem at maiores alias! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio id aspernatur est aliquid, nisi voluptate qui aut, blanditiis eum maiores eos. Commodi nihil dignissimos fugit blanditiis voluptatem at maiores alias!</p>
  <br/>
  <h2 style={{fontWeight:'bold'}}>Leave your comments</h2>

        </div>
<div style={{marginLeft:'50px',marginRight:"50px"}}>
        <Grid container spacing={2} >
  <Grid item xs={6}>
  <TextField fullWidth label="Your Name" id="fullWidth" />
  </Grid>
  <Grid item xs={6}>
  <TextField fullWidth label="Your Email" id="fullWidth" />
  </Grid>
  <Grid item xs={12}>
  <TextField
  fullWidth
  label="Comments"
  id="fullWidth"
  multiline // Enables multiline input
  rows={4} // Sets the number of rows
  sx={{ height: '150px' }} // Custom height
/>

  </Grid>
  
</Grid>
<p style={{fontWeight:'bolder',color:'#646464'}}>Save my name, email, and website in this browser for the next time I comment.</p>
</div>

<button className="btn-view-more" style={{marginLeft:'40px',marginBottom:'10%'}}>SEND</button>
        <Footer />
      
    </>
  );
};

export default GameRoom1;
