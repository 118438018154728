import React from "react";
import gallery from "../../../assets/Gallary1.jpg";
import gallery2 from "../../../assets/Gallary2.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import gallery3 from "../../../assets/gameroom3.jpg"
import gallery4 from "../../../assets/gameroom4.png"
import barimg from "../../../assets/gameroom5.jpg"
const ImageGallery = ({ selectedCategory }) => {
    const navigate = useNavigate();
    const handlenavigate=()=>{
        
        navigate('/gameroom-gallery')
    }
    const handlenavigate1=()=>{
        
        navigate('/gameroom-gallery2')
    }
    const handlenavigate2=()=>{
        
        navigate('/gameroom-gallery3')
    }
    const handlenavigate3=()=>{
        
        navigate('/bar')
    }
  const renderImages = () => {
    switch (selectedCategory) {
      case "bar":
        return (
        
            <div className="bar-image-container"> {/* Apply bar-specific CSS class */}
              <img className="bar-image" src={barimg} style={{ height: "350px", width: "300px" }} onClick={handlenavigate3} />
              <div className="bar-overlay" onClick={handlenavigate3}></div> 
              <div className="bar-image-text" onClick={handlenavigate3}>BAR</div> 
            </div>
        
          
        );
      case "game-room":
        return (
            <div class="small-image">
  <div class="rows">
    
    <div class="game-room-image-container">
      <img src={gallery2} class="game-room-image" style={{ height: '350px', width: '300px' }} onClick={handlenavigate}/>
      <div class="game-room-overlay" onClick={handlenavigate}></div>
      <div class="game-room-image-text" onClick={handlenavigate}>GAME ROOM</div>
    </div>
    
    <div class="game-room-image-container">
      <img src={gallery3} class="game-room-image" style={{ height: '350px', width: '300px' }} onClick={handlenavigate1}/>
      <div class="game-room-overlay" onClick={handlenavigate1}></div>
      <div class="game-room-image-text" onClick={handlenavigate1}>GAME ROOM2</div>
    </div>
    <div class="game-room-image-container">
      <img src={gallery4} class="game-room-image" style={{ height: '350px', width: '300px' }} onClick={handlenavigate2} />
      <div class="game-room-overlay" onClick={handlenavigate2}></div>
      <div class="game-room-image-text" onClick={handlenavigate2}>GAME ROOM</div>
    </div>
  </div>
  </div>
  
    


        );
      case "tournaments":
        return (
            <div class="container">
            <div class="grid-container">
            <NavLink to="/all-gallery">
            <div class="big-image">
              <img src={gallery} style={{height: '710px'}} />
              <div class="image-text">TOURNAMENTS</div>
          </div>    
          </NavLink>
            </div>
          </div>
        );
      default:
        // Render all images if no specific category selected
        return (
            
            <div class="grid-container">
                <NavLink to="/all-gallery">
            <div class="big-image">
            <img src={gallery} style={{height: '710px'}} />
              <div class="image-text">TOURNAMENTS</div>
              
          </div>
          </NavLink> 
          <div class="small-images">
            <div class="row">
                
              <div class="image-container">
                
                <img src={gallery2} style={{ height: '350px', width: '300px' }} onClick={handlenavigate} />
                <div class="overlay" onClick={handlenavigate}></div>
                <div class="image-text" onClick={handlenavigate}>GAME ROOM</div>
              </div>
              <div class="image-container">
                <img src={gallery3} style={{ height: '350px', width: '300px' }} onClick={handlenavigate1} />
                <div class="overlay" onClick={handlenavigate1}></div>
                <div class="image-text" onClick={handlenavigate1}>GAME ROOM</div>
              </div>
            </div>
            <div class="row">
              <div class="image-container">
                <img src={gallery4} style={{ height: '350px', width: '300px' }} onClick={handlenavigate2} />
                <div class="overlay" onClick={handlenavigate2}></div>
                <div class="image-text" onClick={handlenavigate2}>GAME ROOM</div>
              </div>
              <div class="image-container">
                <img src={barimg} style={{ height: '350px', width: '300px' }} onClick={handlenavigate3}/>
                <div class="overlay" onClick={handlenavigate3}></div>
                <div class="image-text" onClick={handlenavigate3}>BAR</div>
              </div>
            </div>
          </div>
          
              
              
            </div>
          
        );
    }
  };

  return (
    <>
      {renderImages()}
      </>
  );
};

export default ImageGallery;
