import React from "react";
import Logo from "../../../../../src/assets/logo.png";
import {
  FaBehance,
  FaGooglePlusG,
  FaFacebookF,
  FaPinterestP,
} from "react-icons/fa";
const Footer = ({ title }) => {
  return (
    <>
      <section className="footer-pnl">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h3>CONTACT US</h3>
              <div className="footer-info">
                <p className="m-inline">
                  <span>Address:</span> 6591 Orchard Street,
                </p>
                <p className="m-inline">NY 10002, United States</p>
                <p>
                  <span>Email:</span> contact@com{" "}
                </p>
                <p>
                  <span>Phone:</span> +00 (9) 123 3456 788
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <img src={Logo} alt="" className="logo-wdth" />
              <ul className="list-social">
                <li>
                  <FaBehance />
                </li>
                <li>
                  <FaGooglePlusG />
                </li>
                <li>
                  <FaFacebookF />
                </li>
                <li>
                  <FaPinterestP />
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <div className="form-pnl">
                <input type="text" />
                <button className="btn-submit">Submit</button>
                <p className="footer-txt">
                  Join the, and get the latest info on Club, events and special
                  offers.
                </p>
              </div>
            </div>
            <div className="col-md-12 copyright-text">
              <span>CRAZY CUES (C) 2024 ALL RIGHTS RESERVED</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
