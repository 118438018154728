import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/css/main.css";
import "bootstrap/dist/css/bootstrap.css";
import welcomeBanner from "../../../assets/welcome-banner.jpg";
import event1 from "../../../assets/event1.jpg";
import Gpin from "../../../assets/Gpin.png";
import ICNShare from "../../../assets/share-icon.png";
import latestPhoto from "../../../assets/latest-photo.jpg";
import fImg1 from "../../../assets/shipitem1.jpg";
import fImg2 from "../../../assets/featured1.jpg";
import fImg3 from "../../../assets/featured3.jpg";
import fImg4 from "../../../assets/featured2.jpg";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Footer from "modules/home/components/footer";
import Header from "modules/home/components/header";
import { Button, Card, CardActions, CardContent, CardMedia, MenuItem, TextField, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import shopitem2 from "../../../assets/shopitem2.jpg"
import shopitem3 from "../../../assets/shopitem3.jpg"
import shopitem4 from "../../../assets/shopitem4.jpg"
import shopitem5 from "../../../assets/shopitem5.jpg"
import shopitem6 from "../../../assets/shopitem6.jpg"
import aboutustip2 from "../../../assets/aboutustip2.jpg"
import aboutustip3 from "../../../assets/aboutustip3.jpg"
import aboutustip4 from "../../../assets/aboutustip4.jpg"
import aboutustip5 from "../../../assets/aboutustip5.jpg"
import aboutustip6 from "../../../assets/aboutustip6.jpg"
import { useNavigate } from "react-router-dom";
const ShopPage = () => {
    const [selectedCategory, setSelectedCategory] = useState("all");
    
    const handleCategoryChange = (category) => {
      setSelectedCategory(category);
    };
  return (
    <>
    <section>
      <div className="headershopPage-bg">
        <Header />
        <div className="banner-caption">
          <div></div>
          <div style={{ width: "70%", marginTop: "12%" }}>
            <span style={{ marginLeft: "23%" }}>SPORT CLUB</span>
            <h2 style={{ marginLeft: "10%" }}>ABOUT US</h2>
          </div>
        </div>
      </div>

        <div class="container">
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "20px" }}>
          <div>
            <span >Showing all 10 results</span>
          </div>
          <div>
            <TextField 
        
              select
              label="Select category"
              value={selectedCategory}
              onChange={(e) => handleCategoryChange(e.target.value)}
              variant="outlined"
              sx={{ width: "250px"}}
            >
              <MenuItem value="all">Default sorting</MenuItem>
              <MenuItem value="category1">Default sorting</MenuItem>
              <MenuItem value="category2">Sort by Popularity</MenuItem>
              <MenuItem value="category3">Sort by avarage rating</MenuItem>
              <MenuItem value="category4">Sort by latest</MenuItem>
              <MenuItem value="category5">Sort by price:low to high</MenuItem>
              <MenuItem value="category6">Sort by price:high to low</MenuItem>
              
            </TextField>
          </div>
        </div>
  <div class="row">
    <div class="col-sm product-item">
     
      <img style={{width:'100%'}} src={fImg1} alt="" />
      <h3 className="featured-title" style={{marginLeft:'70px'}}>Dexter Women’s SST 8 LE Bowling</h3>
      <div className="featured-price" style={{marginLeft:'160px'}}>
        <span>$</span>
        <span>57.00</span>
      </div>
      <button className="btn-carts" style={{marginLeft:'90px'}}>Add to Cart</button>
    
    </div>
    <div class="col-sm product-item">
    <img style={{width:'100%'}} src={fImg2} alt="" />
      <h3 className="featured-title" style={{marginLeft:'70px'}}>Dexter Women’s SST 8 LE Bowling</h3>
      <div className="featured-price" style={{marginLeft:'160px'}}>
        <span>$</span>
        <span>57.00</span>
      </div>
      <button className="btn-carts" style={{marginLeft:'90px'}}>Add to Cart</button>
    
    </div>
    <div class="col-sm product-item">
    <img style={{width:'100%'}} src={fImg1} alt="" />
      <h3 className="featured-title" style={{marginLeft:'70px'}}>Dexter Women’s SST 8 LE Bowling</h3>
      <div className="featured-price" style={{marginLeft:'160px'}}>
        <span>$</span>
        <span>57.00</span>
      </div>
      <button className="btn-carts" style={{marginLeft:'90px'}}>Add to Cart</button>
    
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-sm product-item">
     
      <img style={{width:'100%'}} src={shopitem2} alt="" />
      <h3 className="featured-title" style={{marginLeft:'70px'}}>Dexter Women’s SST 8 LE Bowling</h3>
      <div className="featured-price" style={{marginLeft:'160px'}}>
        <span>$</span>
        <span>57.00</span>
      </div>
      <button className="btn-carts" style={{marginLeft:'90px'}}>Add to Cart</button>
    
    </div>
    <div class="col-sm product-item">
    <img style={{width:'100%'}} src={fImg4} alt="" />
      <h3 className="featured-title" style={{marginLeft:'70px'}}>Dexter Women’s SST 8 LE Bowling</h3>
      <div className="featured-price" style={{marginLeft:'160px'}}>
        <span>$</span>
        <span>57.00</span>
      </div>
      <button className="btn-carts" style={{marginLeft:'90px'}}>Add to Cart</button>
    
    </div>
    <div class="col-sm product-item">
    <img style={{width:'100%'}} src={shopitem3} alt="" />
      <h3 className="featured-title" style={{marginLeft:'70px'}}>Dexter Women’s SST 8 LE Bowling</h3>
      <div className="featured-price" style={{marginLeft:'160px'}}>
        <span>$</span>
        <span>57.00</span>
      </div>
      <button className="btn-carts" style={{marginLeft:'90px'}}>Add to Cart</button>
      
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-sm product-item">
     
      <img style={{width:'100%'}} src={shopitem4} alt="" />
      <h3 className="featured-title" style={{marginLeft:'70px'}}>Dexter Women’s SST 8 LE Bowling</h3>
      <div className="featured-price" style={{marginLeft:'160px'}}>
        <span>$</span>
        <span>57.00</span>
      </div>
      <button className="btn-carts" style={{marginLeft:'90px'}}>Add to Cart</button>
    
    </div>
    <div class="col-sm product-item">
    <img style={{width:'100%'}} src={fImg3} alt="" />
      <h3 className="featured-title" style={{marginLeft:'70px'}}>Dexter Women’s SST 8 LE Bowling</h3>
      <div className="featured-price" style={{marginLeft:'160px'}}>
        <span>$</span>
        <span>57.00</span>
      </div>
      <button className="btn-carts" style={{marginLeft:'90px'}}>Add to Cart</button>
    
    </div>
    <div class="col-sm product-item">
    <img style={{width:'100%'}} src={shopitem5} alt="" />
      <h3 className="featured-title" style={{marginLeft:'70px'}}>Dexter Women’s SST 8 LE Bowling</h3>
      <div className="featured-price" style={{marginLeft:'160px'}}>
        <span>$</span>
        <span>57.00</span>
      </div>
      <button className="btn-carts" style={{marginLeft:'90px'}}>Add to Cart</button>
      
    </div>
  </div>
</div>
<div class="container" style={{marginBottom:'10%'}}>
  <div class="row">
    <div class="col-sm product-item">
     
      <img style={{width:'100%'}} src={shopitem6} alt="" />
      <h3 className="featured-title" style={{marginLeft:'70px'}}>Dexter Women’s SST 8 LE Bowling</h3>
      <div className="featured-price" style={{marginLeft:'160px'}}>
        <span>$</span>
        <span>57.00</span>
      </div>
      <button className="btn-carts" style={{marginLeft:'90px'}}>Add to Cart</button>
    
    </div>
    <div class="col-sm ">
    </div>
    <div class="col-sm ">
    
    </div>
  </div>
</div>
      <Footer />
    </section>
  </>
  );
};

export default ShopPage;
